module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":false,"withWebp":true,"quality":69,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-NRDZ9B5","cookieName":"cookie-consent","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../plugins/gatsby-plugin-page-progress-local/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":[{"regex":"^/blog/.*/"},{"regex":"^/success/.*/"}],"excludePaths":["/blog","/success",{"regex":"^/blog/page/"},{"regex":"^/blog/category/"}],"height":10,"prependToBody":false,"color":"#f43e70","footerHeight":2250},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Databowl","short_name":"Databowl","start_url":"/","background_color":"#1f1a3c","theme_color":"#1f1a3c","lang":"en","display":"standalone","icon":"src/img/databowl-icon-w-pink.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"52fa9eafead533e93bac81c5ab55f7d6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/builds/databowl/databowl-site-v2/src/cms/cms.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
